import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { upcomingLifecycle } from '@Global/utils/games/consts';
import { GamesFilterInitialState, gamesFilterSliceProto } from './consts';

type HomeScreenAllGamesFiltersInitialState = GamesFilterInitialState;

export const homeScreenAllGamesFilters = createSlice<
  HomeScreenAllGamesFiltersInitialState,
  SliceCaseReducers<HomeScreenAllGamesFiltersInitialState>
>({
  name: 'homeScreenAllGamesFilters',
  ...{
    ...gamesFilterSliceProto,
    initialState: {
      ...gamesFilterSliceProto.initialState,
      lifecycle: upcomingLifecycle,
    },
  },
});

export const homeScreenAllGamesFiltersReducer = homeScreenAllGamesFilters.reducer;
