import React from 'react';
import { Logo } from '@/ui/logo/index.jsx';

/** Логотип */
export const Preview = () => {
  return (
    <div className="preview gradient-special">
      <Logo />
    </div>
  );
};
