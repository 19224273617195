import { PlayerStatValueType, WalletProvider } from '@Global/apollo/types';
import { EAppRoute } from './routes';

export enum ENftLeague {
  'BEGINNER' = 'BEGINNER',
  'LITE' = 'LITE',
  'AMATEUR' = 'AMATEUR',
  'SEMIPRO' = 'SEMIPRO',
  'PRO' = 'PRO',
  'LEGENDARY' = 'LEGENDARY',
  'MIXZONE' = 'MIXZONE',
}

export enum EGameLabel {
  RUSSIA = 'RUS',
  EUROPA_LEAGUE = 'EURL',
  CONFERENCE_LEAGUE = 'CONFL',
  TOP5 = 'TOP5',
  AMATEUR = 'AMATEUR',
  PRO = 'PRO',
  PREMIUMM = 'PREMIUM',
  EPL = 'EPL',
  SERIE_A = 'SERIE_A',
  LALIGA = 'LALIGA',
  LIGA1 = 'LIGA1',
  BUNDESLIGA = 'BUNDESLIGA',
  CL = 'CL',
  BRA1 = 'BRA1',
  EURO2024 = 'EURO2024',
  WC2022 = 'WC2022',
  PREMIUML = 'PREMIUM+',
  PREMIUMS = 'PREMIUMS',
  MLS = 'MLS',
  MAIN = 'MAIN',
  WINLINE = 'winline',
}

export const tournamentsLabels = [
  EGameLabel.TOP5,
  EGameLabel.EPL,
  EGameLabel.RUSSIA,
  EGameLabel.SERIE_A,
  EGameLabel.LALIGA,
  EGameLabel.LIGA1,
  EGameLabel.BUNDESLIGA,
  EGameLabel.BRA1,
  EGameLabel.CL,
  EGameLabel.EUROPA_LEAGUE,
  EGameLabel.CONFERENCE_LEAGUE,
  EGameLabel.EURO2024,
  EGameLabel.MLS,
  EGameLabel.WINLINE,
];

export type TWalletProviderWithOtherWallet = WalletProvider | 'OTHER_WALLET';

export type TWalletProviderWithOtherWalletAndTelegramWallet =
  | TWalletProviderWithOtherWallet
  | 'TELEGRAM_WALLET'
  | 'TONSPACE_WALLET'
  | 'TON_WALLET';

export type TWalletProviderWithOtherWalletAndTonSpaceAndTelegramWallet =
  | TWalletProviderWithOtherWalletAndTelegramWallet
  | 'TONSPACE_WALLET';

export const PRODUCTION_BOT_URL = 'https://t.me/FanTonGameBot';

export const QA_BOT_URL = 'https://t.me/FantonGameQaBot';

export const STARTPATH_QUERY_PARAM_NAME = 'startPath';

export const ACTION_QUERY_PARAM_NAME = 'action';

export const COMMIT_TEAM_ACTION = 'commitTeam';

export const PAYMENT_CURRENCY_QUERY_PARAM_NAME = 'currency';

export const BASE_URL = 'https://app.fan-ton.com';

enum PlayerStatValueTypeExtension {
  Cleansheet = 'CLEANSHEET',
  AveragePoints = 'AVERAGE_POINTS',
}

export const ExtendedPLayerStatValueType = {
  ...PlayerStatValueType,
  ...PlayerStatValueTypeExtension,
} as const;

export type TExtendedPLayerStatValueTypeKey = keyof typeof ExtendedPLayerStatValueType;

export type TExtendedPLayerStatValueTypeValue = typeof ExtendedPLayerStatValueType[TExtendedPLayerStatValueTypeKey];

export const IS_LOCAL = Boolean(process.env.REACT_APP_IS_LOCAL);

export const PVP_ROUTES: string[] = [EAppRoute.PVP_GAMES, EAppRoute.MY_PVP_GAMES];

export const EURO_ROUTES: string[] = [EAppRoute.EURO2024];
export const WINLINE_ROUTES: string[] = [EAppRoute.WINLINE];

export const GAME_TO_JUMP_ON_START_ID = 'GAME_TO_JUMP_ON_START_ID';
export const GAME_TO_JUMP_ON_SUBMISSION_TASKS_ID = 'GAME_TO_JUMP_ON_SUBMISSION_TASKS_ID';
export const LOOTBOXES_INTRO_WATCHED = 'LOOTBOXES_INTRO_WATCHED';
export const LOOTBOXES_INTRO_WATCHED_CARDS = 'LOOTBOXES_INTRO_WATCHED_CARDS';

export const TOPPED_UP_BALANCE = 'topped_up_the_balance';

export const FREE_FIRST_TEAM = 'free_first_team';
export const ANY_PLAYER_LABEL = 'any_player';
export const ALL_PLAYERS_LABEL = 'all_players';
export const ANY_PLAYER_ONBOARDING_SKIPPED = 'any_player_onboarding_skipped';
export const ANY_PLAYER_ONBOARDING_PASSED = 'any_player_onboarding_passed';

export const CAPPED_LABEL = 'capped';
