import { useTranslation } from 'react-i18next';
import { TFantonAppTranslation } from '@Global/i18n';

type PathsToStringProps<T> = T extends string
  ? []
  : {
      [K in Extract<keyof T, string>]: [K, ...PathsToStringProps<T[K]>];
    }[Extract<keyof T, string>];

type Join<T extends string[], D extends string> = T extends []
  ? never
  : T extends [infer F]
  ? F
  : T extends [infer F, ...infer R]
  ? F extends string
    ? `${F}${D}${Join<Extract<R, string[]>, D>}`
    : never
  : string;

export type TFantonAppTranslationsKey = Join<PathsToStringProps<TFantonAppTranslation>, '.'>;

export const useFantonTranslation = (keyPrefix?: string) => {
  const { t } = useTranslation('translations', { keyPrefix });
  return t as (key: TFantonAppTranslationsKey, params?: any) => string;
};
