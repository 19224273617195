import { createSlice } from '@reduxjs/toolkit';
import { upcomingLifecycle } from '@Global/utils/games/consts';
import { GamesFilterInitialState, gamesFilterSliceProto } from './consts';

export const pvpAllGamesFilters = createSlice({
  name: 'pvpAllGamesFilters',
  ...{
    ...gamesFilterSliceProto,
    initialState: {
      ...gamesFilterSliceProto.initialState,
      lifecycle: upcomingLifecycle,
    } as GamesFilterInitialState,
  },
});

export const pvpAllGamesFiltersReducer = pvpAllGamesFilters.reducer;
