export enum EAppRoute {
  PVP_GAMES = '/pvp-games',
  LEADERBOARDS = '/leaderboards',
  LEADERBOARDS_LEVELS_PRIZES = '/leaderboards-levels-prizes/:scoringSystemVersion',
  LEADERBOARDS_LEAGUE = `/leaderboards/league`,
  LEADERBOARDS_SEASONS = `/leaderboards/seasons`,
  LEADERBOARDS_CHALLENGE = `/leaderboards/challenge/:challengeId`,
  LEADERBOARDS_CHALLENGE_LEADERS = `/leaderboards/challenge/:challengeId/leaders`,
  LEADERBOARDS_CHALLENGE_LEADER = `/leaderboards/challenge/:challengeId/leaders/:participantId`,
  LEADERBOARDS_CHALLENGE_PRIZES = `/leaderboards/challenge/:challengeId/prizes`,
  LEADERBOARDS_SCORING = `/leaderboards/scoring/:scoringSystemVersion`,
  MY_PVP_GAMES = '/my-pvp-games/:currentOrPast',
  DAILY_SPIN = '/daily-spin',
  CLAIM_ROULETTE_PRIZE = '/claim-roulette-prize',
  LOOTBOX_INFO = '/loot-box-info',
  DISTRIBUTED_RULE_FILTERED_CARDS = '/distributed-rule-filtered-cards',
  LOOTBOX_PURCHASE = '/lootbox-purchase',
  EURO2024 = '/euro2024',
  TG_STARS = '/tg-stars',
  EURO2024_PLAYOFF = '/euro2024-playoff',
  UNITED_TON_DEPOSIT = '/united-ton-deposit',
  ENTER_AMOUNT = '/enter-amount',
  SELECT_WALLET = '/select-wallet',
  SELECT_WALLET_ADRESS = '/select-wallet-adress', // Leaving it as is in case of possible incoming links with a typo from other sources
  SELECT_WALLET_ADDRESS = '/select-wallet-address',
  WAITING_FOR_TRANSFER = '/waiting-for-transfer',
  WALLET = '/wallet',
  OTHER_WALLET_TRANSFER = '/other-wallet-transfer',
  SEND_MONEY = '/send-money',
  SWAP_MONEY = '/swap-money',
  SWAP_MONEY_REQUEST_CREATED = '/swap-money-request-created',
  STAKE_MONEY = '/stake-money',
  STAKE_MONEY_GUIDE = '/stake-money-guide',
  STAKE_MONEY_CREATED = '/stake-money-created',
  STAKE_MONEY_DETAILS = '/stake-money-details',
  WITHDRAWAL_REQUEST_CREATED = '/withdrawal-request-created',
  CONNECT_WALLET = '/connect-wallet',
  WINLINE = '/winline',
  WINLINE_PLAYOFF = '/winline-playoff',
  LISTING_PAGE = '/listing',
  TOURNAMENT = '/tournament/:id/',
  TOURNAMENT_EDIT = '/tournament/:id/edit',
  TOURNAMENT_TEAM = '/tournament/:id/team',
  TOURNAMENT_TEAM_DETAILS = '/tournament/:id/team/:teamId/team-details',
  TOURNAMENT_TEAM_DETAILS_NO_ID = '/tournament/:id/team-details',
  TOURNAMENT_TEAM_EDIT_SELECT_PLAYER_FILTERS = '/tournament/:id/team/:teamId/edit/select-player/filters',
  TOURNAMENT_EDIT_SELECT_PLAYER_FILTERS = '/tournament/:id/edit/select-player/filters',
  TOURNAMENT_TEAM_EDIT_SELECT_PLAYER = '/tournament/:id/team/:teamId/edit/select-player',
  TOURNAMENT_EDIT_SELECT_PLAYER = '/tournament/:id/edit/select-player',
  TOURNAMENT_TEAM_EDIT = '/tournament/:id/team/:teamId/edit',
  TOURNAMENT_TEAM_RESULTS = '/tournament/:id/team/:teamId/results',
  TOURNAMENT_RESULTS = '/tournament/:id/results',
  TOURNAMENT_TEAM_POSITION_DETAILS = '/tournament/:id/team/:teamId/position-details',
  TOURNAMENT_POSITION_DETAILS = '/tournament/:id/position-details',
  TOURNAMENT_TEAM_MATCHES = '/tournament/:id/team/:teamId/matches',
  TOURNAMENT_MATCHES = '/tournament/:id/matches',
  TOURNAMENT_TEAM_PRIZES = '/tournament/:id/team/:teamId/prizes',
  TOURNAMENT_PRIZES = '/tournament/:id/prizes',
  TOURNAMENT_TEAM_BEFORE_SUBMISSION_TASKS = '/tournament/:id/team/:teamId/before-sumbission-tasks',
  TOURNAMENT_BEFORE_SUBMISSION_TASKS = '/tournament/:id/before-sumbission-tasks',
  TOURNAMENT_TEAM_CREATED = '/tournament/:id/team/:teamId/team-created',
  TOURNAMENT_CREATED = '/tournament/:id/team-created',
  TOURNAMENT_TEAM_PACK_CLAIMED = '/tournament/:id/team/:teamId/pack-claimed',
  TOURNAMENT_PACK_CLAIMED = '/tournament/:id/pack-claimed',
  TOURNAMENT_TEAM_PLAYER_DETAILED_INFO = '/tournament/:id/team/player-detailed-info/:playerId',
  TOURNAMENT_PLAYER_DETAILED_INFO = '/tournament/:id/player-detailed-info/:playerId',
}
