import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { Loader2 } from 'lucide-react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../lib/utils';

// const buttonVariants = cva(
//   'tw-inline-flex tw-items-center tw-justify-center tw-gap-2 tw-whitespace-nowrap tw-rounded-md tw-text-sm tw-font-medium tw-ring-offset-background tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-pointer-events-none disabled:tw-opacity-50 [&_svg]:tw-pointer-events-none [&_svg]:tw-size-4 [&_svg]:tw-shrink-0',
//   {
//     variants: {
//       variant: {
//         default: 'tw-bg-primary tw-text-primary-foreground hover:tw-bg-primary/90',
//         destructive: 'tw-bg-destructive tw-text-destructive-foreground hover:tw-bg-destructive/90',
//         outline: 'tw-border tw-border-input tw-bg-background hover:tw-bg-accent hover:tw-text-accent-foreground',
//         secondary: 'tw-bg-secondary tw-text-secondary-foreground hover:tw-bg-secondary/80',
//         ghost: 'hover:tw-bg-accent hover:tw-text-accent-foreground',
//         link: 'tw-text-primary tw-underline-offset-4 hover:tw-underline',
//       },
//       size: {
//         default: 'tw-h-10 tw-px-4 tw-py-2',
//         sm: 'tw-h-9 tw-rounded-md tw-px-3',
//         lg: 'tw-h-11 tw-rounded-md tw-px-8',
//         icon: 'tw-h-10 tw-w-10',
//       },
//     },
//     defaultVariants: {
//       variant: 'default',
//       size: 'default',
//     },
//   },
// );

const buttonVariants = cva(
  'tw-w-full tw-flex tw-justify-center tw-items-center tw-rounded-lg tw-box-border tw-font-roboto tw-font-normal tw-transition-colors disabled:tw-opacity-50 disabled:tw-pointer-events-none focus-visible:tw-outline-none',
  {
    variants: {
      size: {
        lg: 'tw-py-4 tw-px-0 tw-text-base/[20px]',
        md: 'tw-py-2 tw-px-4 tw-text-sm',
      },
      variant: {
        default: 'tw-bg-surface-active tw-border-solid tw-border-surface-active tw-text-text-primary ',
        secondary: 'tw-bg-surface-secondary',
        outline: 'tw-border tw-border-solid tw-bg-transparent tw-text-text-inverse tw-border-surface-active',
        ghost: 'tw-bg-transparent',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'lg',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, children, isLoading, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props}>
        {isLoading && <Loader2 className="tw-animate-spin" />}
        {!isLoading && children}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
