import { TonConnectUIProvider } from '@tonconnect/ui-react';
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { Viewer } from '@Global/apollo/types';
import { GuestAccountID } from '@Global/constants';
import { AppContextProvider } from './appContext';
import { TeamContext, TeamContextProvider } from './teamContext';

type TViewerContext = {
  viewer?: Viewer;
  isGuest: boolean;
  key?: string;
  updateViewer: (newViewer?: Viewer, key?: string) => void;
};

type TUserFilterContext = {
  values: Record<string, any>;
  setUserFilter: Dispatch<SetStateAction<Record<string, any>>>;
};

type CardsFilter = {
  sortOptions?: {
    key: 'L5' | 'L15';
    value: 'ASC' | 'DSC';
  };
  capFilterEnabled?: boolean;
  nftFilterEnabled?: boolean;
} & Record<string, any>;

type TFilterContext = {
  cards: CardsFilter;
  setCardsFilter: Dispatch<SetStateAction<CardsFilter>>;
};

const viewerStore: TViewerContext = {
  viewer: undefined,
  isGuest: true,
  key: undefined,
  updateViewer: () => undefined,
};

const userFilterStore: TUserFilterContext = {
  values: {},
  setUserFilter: () => {},
};

const filterStore: TFilterContext = {
  cards: {},
  setCardsFilter: () => {},
};

export const FilterContext = React.createContext(filterStore);
export const UserFilterContext = React.createContext(userFilterStore);
export const ViewerContext = React.createContext(viewerStore);
export { TeamContext };

/** прослойка контекста фильтрации */
export const ContextInterLayer: React.FC<React.PropsWithChildren> = ({ children }: React.PropsWithChildren) => {
  const [viewer, setViewer] = useState<Viewer | undefined>(undefined);
  const [viewerKey, setViewerKey] = useState<string | undefined>(undefined);
  const [cards, setCardsFilter] = useState({});
  const [userFilter, setUserFilter] = useState({});

  const isGuestViewer = useMemo(() => !viewer || viewer.id === GuestAccountID, [viewer]);

  const onUpdateViewer = useCallback((newViewer?: Viewer, key?: string) => {
    setViewer(() => (newViewer ? { ...newViewer } : undefined));
    setViewerKey(() => key);
  }, []);

  const onFilterChange = setCardsFilter;

  const onUserFilterChange = setUserFilter;

  return (
    <AppContextProvider>
      <TonConnectUIProvider manifestUrl={process.env.REACT_APP_TON_CONNECT_MANIFEST_URL}>
        <ViewerContext.Provider
          value={{ viewer, key: viewerKey, isGuest: isGuestViewer, updateViewer: onUpdateViewer }}
        >
          <TeamContextProvider setUserFilter={setUserFilter}>
            <FilterContext.Provider value={{ cards, setCardsFilter: onFilterChange }}>
              <UserFilterContext.Provider value={{ values: userFilter, setUserFilter: onUserFilterChange }}>
                {children}
              </UserFilterContext.Provider>
            </FilterContext.Provider>
          </TeamContextProvider>
        </ViewerContext.Provider>
      </TonConnectUIProvider>
    </AppContextProvider>
  );
};
