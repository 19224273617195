import * as Sentry from '@sentry/react';

export const initSentry = () => {
  if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENVIRONMENT === 'prod') {
    Sentry.init({
      dsn:
        process.env.REACT_APP_SENTRY_DSN ||
        'https://a08b30d95ebe6e77dc783230d041aea2@o4508683132993536.ingest.de.sentry.io/4508686971699280',
      // Environment settings
      environment: process.env.REACT_APP_ENVIRONMENT,
      release: process.env.REACT_APP_VERSION || '1.0.0',

      // Ignore specific errors
      ignoreErrors: [
        // Add patterns for errors you want to ignore
        'Network request failed',
        'ResizeObserver loop limit exceeded',
      ],
      beforeSend(event) {
        // Only send errors from ErrorBoundary
        if (event.exception) {
          return event;
        }
        return null;
      },
    });
  }
};
