import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GetCardsQuery } from '@Apollo/query/getCards/gql/getCards.generated';
import { JwtTokenName } from '@/constants';
import { GameConnection, UserTeamConnection } from '@Apollo/types';
import { getInitDataSource } from '@Apollo/utils/getInitDataSource';
import { getUriSource } from '@Apollo/utils/getUriSource';

const isDev = process.env.NODE_ENV === 'development';

type CardConnection = GetCardsQuery['cards'];

const httpLink = (uri: string, headers: Record<string, string>) =>
  createHttpLink({
    uri: (operation) => {
      if (isDev && operation.operationName && 'operation' in operation.query.definitions[0]) {
        const method = operation.query.definitions[0].operation;
        try {
          const url = new URL(uri, window.location.origin);
          url.searchParams.set(method, operation.operationName);
          return url.toString();
        } catch (error) {
          console.error('Error can not add query to URI:', error);
        }
      }
      return uri;
    },
    headers,
  });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(JwtTokenName);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

/** создание apollo клиента */
export const createApolloClient = () => {
  const uri = getUriSource();
  const telegramData = getInitDataSource();
  const storedTelegramInitData = localStorage.getItem('telegramInitData');

  const queryParams = new URLSearchParams(window.location.search);
  const superInitData = queryParams.get('super_init_data');

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  };

  const tgUserInitData =
    storedTelegramInitData || (superInitData && atob(superInitData)) || window.Telegram.WebApp.initData || telegramData;
  if (tgUserInitData) {
    headers['Telegram-Init-Data'] = tgUserInitData;
  }

  return new ApolloClient({
    link: authLink.concat(httpLink(uri, headers)),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            games: {
              keyArgs: [
                'myOwn',
                'lifecycle',
                'tournaments',
                'hasGiftToClaim',
                'isPaid',
                'isNft',
                'labels',
                'isMainEvent',
                'isOneMatchGame',
                'isPublic',
                'isCreatedByMe',
                'isPvP',
                'ticketPrice',
                'isSharedByMe',
                'features',
              ],
              merge(existingGameConnection: GameConnection, incomingGameConnection: GameConnection) {
                if (!incomingGameConnection) return existingGameConnection;

                const prevList = existingGameConnection?.edges || [];

                // @ts-expect-error
                const prevListIds = new Set(prevList.map(({ node }) => node.__ref));

                const filteredIncoming = incomingGameConnection.edges.filter(
                  // @ts-expect-error
                  ({ node }) => !prevListIds.has(node.__ref),
                );

                const newList = [...prevList, ...filteredIncoming];

                return {
                  ...incomingGameConnection,
                  edges: newList,
                };
              },
            },
            cards: {
              // Аргументы запроса, которые влияют на результат
              keyArgs: [
                'myOwn',
                'name',
                'tournaments',
                'teams',
                'positions',
                'citizenship',
                'rarities',
                'tiers',
                'games',
                'userTeams',
                'excludedCards',
                'isOnSale',
                'isAtAuction',
                'price',
                'orderBy',
                'goalsAvg',
                'goalsLast5',
                'assistsAvg',
                'assistsLast5',
                'minutesPlayedAvg',
                'minutesPlayedLast5',
                'cleanSheetsAvg',
                'cleanSheetsLast5',
                'isFree',
                'avgScoreLast15',
                'avgScoreLast5',
              ],
              // Логика объединения данных при пагинации
              merge(
                existing: CardConnection = { edges: [], pageInfo: { hasNextPage: false, endCursor: null } },
                incoming: CardConnection,
              ): CardConnection {
                if (!incoming) return existing;

                const prevList = existing?.edges || [];

                // @ts-expect-error
                const prevListIds = new Set(prevList.map(({ node }) => node.__ref));

                const filteredIncoming = incoming.edges.filter(
                  // @ts-expect-error
                  ({ node }) => !prevListIds.has(node.__ref),
                );

                const newList = [...prevList, ...filteredIncoming];

                return {
                  ...incoming,
                  edges: newList,
                };
              },
            },
          },
        },
        Game: {
          fields: {
            teams: {
              keyArgs: ['groupIndex'],
              merge(existingUserTeamConnection: UserTeamConnection, incomingUserTeamConnection: UserTeamConnection) {
                if (!incomingUserTeamConnection) return existingUserTeamConnection;

                const prevList = existingUserTeamConnection?.edges || [];

                // @ts-expect-error
                const prevListIds = new Set(prevList.map(({ node }) => node.__ref));

                const filteredIncoming = incomingUserTeamConnection.edges.filter(
                  // @ts-expect-error
                  ({ node }) => !prevListIds.has(node.__ref),
                );

                const newList = [...prevList, ...filteredIncoming];

                return {
                  ...incomingUserTeamConnection,
                  edges: newList,
                };
              },
            },
          },
        },
      },
    }),
    headers,
  });
};
