import React, { PropsWithChildren, useState } from 'react';
import { getUserLanguage } from '@Global/utils/getUserLanguage';
import { getAppPlatform } from '@Global/utils/platforms';
import { Currency, ExchangeRate } from '@Apollo/types';

// Утилитный тип для генерации всех комбинаций пар валют, исключая пары из одинаковых валют.
type GetPairsFromCurrencies<T extends string, U extends string = T> = T extends any
  ? Exclude<U, T> extends never
    ? never
    : `${T}_${Exclude<U, T>}`
  : never;

/** @deprecated use Pairs type */
export enum ECurrenciesPair {
  TONUSD = 'TONUSD',
  TONBTC = 'TONBTC',
  TONETH = 'TONETH',
  STONUSD = 'STONUSD',
  JETTONUSD = 'JETTONUSD',
  USDTUSD = 'USDTUSD',
  TONFTON = 'TONFTON',
  TONFNTF = 'TONFNTF',
  FTONUSD = 'FTONUSD',
  FNTFUSD = 'FNTFUSD',
  XTRUSD = 'XTRUSD',
  XTRUSDT = 'XTRUSDT',
  USDTXTR = 'USDTXTR',
  USDTTRC20USD = 'USDTTRC20USD',
  USDTERC20USD = 'USDTERC20USD',
  TESTTUSDUSD = 'TESTTUSDUSD',
}

type OldPairs = keyof typeof ECurrenciesPair;

export type Pairs = GetPairsFromCurrencies<Currency>;

type AllPairs = Pairs | OldPairs;

export type TAppContext = {
  locale: string;
  appEnv: TAppEnv;
  currencyRates: {
    [key in AllPairs]: number;
  };
  setRates: (rates: ExchangeRate[]) => void;
};

export type TAppEnv = 'telegram' | 'tonkeeper' | 'neocrypto' | 'web';

const initialState: TAppContext = {
  locale: 'en',
  appEnv: 'telegram',
  currencyRates: {
    USDT_USD: 1,
    USD_USDT: 1,
  } as TAppContext['currencyRates'],
  setRates: () => {},
};

export const AppContext = React.createContext<TAppContext>(initialState);

export const AppContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const locale = getUserLanguage();
  const appEnv = getAppPlatform();

  const [currencyRates, setCurrencyRates] = useState(initialState.currencyRates);

  const setRates = (rates: ExchangeRate[]) => {
    const currencyRates = rates.reduce((acc, rate) => {
      const pair = `${rate.source}_${rate.target}` as Pairs;
      /** we have both versions of exchange rates USDT_TON and USDTTON for backward compatibility */
      const pairWithoutUnderscore = pair.replace('_', '') as OldPairs;
      const amount = parseFloat(rate.amount);

      acc[pair] = amount;
      acc[pairWithoutUnderscore] = amount;
      return acc;
    }, {} as TAppContext['currencyRates']);

    setCurrencyRates((prev) => {
      return {
        ...prev,
        ...currencyRates,
      };
    });
  };

  return <AppContext.Provider value={{ locale, appEnv, currencyRates, setRates }}>{children}</AppContext.Provider>;
};
