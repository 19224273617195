import * as Sentry from '@sentry/react';
import { Component, ErrorInfo, ReactNode } from 'react';
import { useFantonTranslation } from '@Global/hooks/i18n';
import { Button } from '@Global/ui-kit';

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException(error, {
      contexts: {
        react: {
          componentStack: errorInfo.componentStack,
        },
      },
    });
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorView />;
    }

    return this.props.children;
  }
}

const ErrorView = () => {
  const t = useFantonTranslation();

  return (
    <div className="tw-min-h-screen tw-flex tw-items-center tw-justify-center tw-bg-surface-primary">
      <div className="tw-text-center tw-p-8 tw-max-w-lg tw-mx-auto">
        <h1 className="tw-text-4xl tw-font-bold tw-text-gray-100 tw-mb-4">{t('Oops! Something went wrong')}</h1>
        <p className="tw-text-gray-300 tw-mb-8">
          {t('We apologize for the inconvenience. Our team has been notified and is working to fix the issue.')}
        </p>
        <Button onClick={() => window.location.reload()}>{t('Refresh Page')}</Button>
      </div>
    </div>
  );
};
