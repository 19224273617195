import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IdentifyUser } from '@Utils/amplitude';
import { useUpdateLocalTONToCurrenciesRate } from '@Utils/currencies';
import { ErrorBoundary } from '@/ui/ErrorBoundary';
import { Suspense } from '@/ui/suspense/index.jsx';
import { initSentry } from '@/utils/sentry';
import { ViewerContext } from '@Global/store';
import { AppContext } from '@Global/store/appContext';
import { useAppViewer } from '@Apollo/query/getAppViewer/useAppViewer';
import { Preview } from '@Content/pages/preview/index.jsx';
import { useAddBeforeUnloadPromptEffect } from '@Hooks/useAddBeforeUnloadPromptEffect';
import { useParseStartParamDataEffect } from '@Hooks/useParseStartParamDataEffect';

const Content = React.lazy(() => import('./content'));

/** контент entry point */
export const App = () => {
  const { appEnv } = useContext(AppContext);
  const viewerCtx = useContext(ViewerContext);
  const { loading, data } = useAppViewer();
  const [isIdentified, setIsIdentified] = useState(false);
  const history = useHistory();

  useEffect(() => {
    window?.Telegram?.WebApp?.MainButton?.hide();
    if (appEnv === 'telegram') {
      // Initialize Telegram Analytics SDK
      window.telegramAnalytics?.init({
        token:
          'eyJhcHBfbmFtZSI6IkZhbnRvbiIsImFwcF91cmwiOiJodHRwczovL2FwcC5mYW4tdG9uLmNvbSIsImFwcF9kb21haW4iOiJodHRwczovL2FwcC5mYW4tdG9uLmNvbSJ9!rOVjqPRfjkfLPFwEoJ9aGZg0f/GaLTl3tXjC4fMrFKo=',
        appName: 'Fanton',
      });
    }
  }, []);

  useParseStartParamDataEffect();
  useUpdateLocalTONToCurrenciesRate();
  useAddBeforeUnloadPromptEffect();

  useEffect(() => {
    if (!loading && data && !isIdentified) {
      IdentifyUser(data.viewer);
      setIsIdentified(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  return (
    <div className="wrapper">
      <Suspense loading={loading || !data} fallback={appEnv === 'neocrypto' ? () => null : () => <Preview />}>
        {!loading && data && <Content key={viewerCtx.key} />}
      </Suspense>
    </div>
  );
};
