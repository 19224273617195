import { EGameLabel } from '@Global/consts';
import { TFantonAppTranslation } from '@Global/i18n';

const ESTranslation: TFantonAppTranslation = {
  'Oops! Something went wrong': 'Ups! Algo salió mal',
  'We apologize for the inconvenience. Our team has been notified and is working to fix the issue.':
    'Nos disculpamos por las molestias. Nuestro equipo ha sido notificado y está trabajando para solucionar el problema.',
  'Refresh Page': 'Actualizar página',
  'Stars will convert to': 'Stars serán convertidas en',
  Balance: 'Saldo',
  'Average points in the': 'Promedio de puntos en el {{filter}}',
  'Average points': 'Promedio de puntos',
  'any-player-onboarding': {
    label: 'Nuevos partidos individuales',
    description: 'Ahora, tienes la opción de seleccionar cualquier posición de jugador en partidos individuales',
  },
  'community-onboarding': {
    label: `Únete a nuestra comunidad`,
    description: `¡No te pierdas las últimas actualizaciones, torneos y promociones de Fanton!`,
    actions: {
      skip: 'Saltar',
      join: 'Unirse',
    },
  },
  'filters.advanced-settings.label': 'Ajustes avanzados',
  'filters.advanced-settings.reset-btn.label': 'Restablecer ajustes',
  'common.statistics-for-the-last-n-matches': 'Estadísticas de los últimos {{n}} partidos',
  'common.average-for-the-match': 'Promedio del partido',
  'common.min': 'min',
  'common.points': 'Puntos',
  'common.tournaments.labels': {
    [EGameLabel.EPL]: 'Inglaterra',
    [EGameLabel.LALIGA]: 'España',
    [EGameLabel.LIGA1]: 'Francia',
    [EGameLabel.BUNDESLIGA]: 'Alemania',
    [EGameLabel.SERIE_A]: 'Italia',
    [EGameLabel.BRA1]: 'Brasil',
    [EGameLabel.TOP5]: 'TOP-5 United',
    [EGameLabel.WC2022]: 'Qatar 2022',
    [EGameLabel.EURO2024]: 'Euro 2024',
    [EGameLabel.CL]: 'Champions League',
    [EGameLabel.RUSSIA]: 'Rusia',
    [EGameLabel.CONFERENCE_LEAGUE]: 'Conference League',
    [EGameLabel.EUROPA_LEAGUE]: 'Europa League',
  },
  quizes: {
    'correct-answer-label': 'Sí, es la respuesta correcta',
    'wrong-answer-label': '¡Respuesta incorrecta!',
    'skip-label': 'Saltar',
    'try-again-label': 'Intentar de nuevo',
  },
  'common.yes': 'Sí',
  'common.no': 'No',
  'common.select-the': 'Seleccionar',
  'common.tap-on-the': 'Pulsa sobre el',
  'common.tap-here': 'Pulse aquí',
  'cjm-onboarding': {
    'warning-title': '¿Estás seguro de que quieres continuar sin conocer el sistema de puntuación?',
    welcome: {
      title: 'Bienvenido a tu primer torneo',
      action: 'Selecciona al delantero',
      description: ' que anote más puntos en el partido de fútbol real y ¡gana un premio!',
    },
    how: {
      title: '¿Cómo ganan puntos los jugadores?',
    },
    'captain-instructions': {
      title: '¡Felicidades!',
      'description-part-1': ' Has reunido a todo el equipo. Selecciona un capitán haciendo clic en el «',
      'description-highlighted-part-1': 'icono C',
      'description-part-2': '». El capitán multiplica los puntos por ',
      'description-highlighted-part-2': '1.2X',
    },
    quizes: {
      forward: {
        questions: {
          first: '¿Cuántos puntos obtendrá el delantero si marca 2 goles?',
          second: `¿Cuántos puntos obtiene el delantero si realiza 2 asistencias?`,
          third: `¿Cuántos puntos obtiene el delantero si realiza 2 tiros a puerta?`,
        },
      },
    },
    'forward-label-pt-1': 'Si el delantero anota 2 goles',
    'forward-label-pt-2': 'en un partido de fútbol real',
    'forward-description-pt-1': 'Obtendrá',
    'forward-description-pt-2': '140 pts',
    'forward-action': 'Entiendo',
  },
  'scoring.for-every-{{n}}-minutes-in-the-match': 'Por cada {{n}} minutos en el partido',
  'scoring.for-every-{{n}}-miss-passes-in-the-match': 'Por cada {{n}} pases en el partido',
  'scroing.groups': {
    'main-score': 'Puntuación principal',
    passes: 'Pases',
    defensive: 'Defensiva',
    penalty: 'Penales',
    extra: 'Extra',
  },
  'common.position-scoring': 'Puntuación del {{position}}',
  'position-details.reserve.description':
    'Sustitución: un seguro en caso de que uno de tus jugadores titulares no entre al campo en el partido. En este caso, en lugar del jugador que no jugó, recibirás puntos de tu jugador suplente, pero con una penalización del 70% de los puntos.',
  'common.positions': {
    goalkeeper: 'Portero',
    forward: 'Delantero',
    midfielder: 'Medio',
    defender: 'Defensa',
    reserve: 'Jugador suplente',
    back: 'Defensa',
    midfield: 'Medio',
    BACK: 'DEFENSA',
    FORWARD: 'DELANTERO',
    GOALKEEPER: 'PORTERO',
    MIDFIELD: 'MEDIO',
    RESERVE: 'JUGADOR SUPLENTE',
  },
  onboarding: {
    items: {
      welcome: {
        header: '¡Bienvenido a Fanton \nFantasy Football!',
        description: 'Gana dinero con tu pasión por el fútbol',
      },
      team: {
        header: 'Forma un equipo',
        description: 'Elige cinco futbolistas para cada jornada de tus ligas de fútbol favoritas',
      },
      leaderboard: {
        header: 'Juega torneos',
        description: 'Y compite con otros jugadores',
      },
      'card-with-coins': {
        header: '¡Gana dinero real!',
        description: 'Comienza ahora tu primer torneo',
      },
      'real-world': {
        header: 'Consigue puntos por el rendimiento real de tus jugadores',
        'real-world': 'Mundo real',
        goal: 'gol',
        goals: 'Goles',
        assist: 'Asistencias',
        yellow_card: 'Tarjetas amarillas',
        time_played: 'Tiempo jugado',
        and_more: 'Y más..',
      },
    },
    buttons: {
      skip: 'Saltar, soy pro',
      continue: 'Continuar',
      play: 'Iniciar torneo',
    },
  },
  'premium-onboarding': {
    items: {
      'hold-on': {
        header: '¡Espera! Puedes ganar 400 $ en nuestro Torneo Premium',
        description: '¿Se atreve a aceptar el reto?',
      },
      'want-to-win-this-prize': {
        header: '¿Quieres ganar este premio?',
        description: '¡Torneo Premium ahora!',
      },
    },
    buttons: {
      'more-info': 'Más info',
      'play-premium': 'Jugar premium',
      decline: 'No',
    },
  },
  'pvp-promo': {
    label: 'Trofei nei Duelli PvP',
    description: 'Combatti nei Duelli, guadagna Trofei e token $FTON',
    action: 'Play PVP',
  },
  'tonstarter-promo': {
    first: {
      header: 'Oferta exclusiva',
      description: 'Inicia Fanton en Telegram y obtén GRATIS un ticket para un Torneo Premium',
      action: 'Obtener ticket',
    },
    second: {
      header: 'Un premio de $30 000 espera por ti',
      description: 'Incentivo Fanton x Tonstarter',
      action: 'Únete ahora',
    },
    third: {
      header: 'Pool de premios de 30,000 $ en tokens $FTON',
      action: 'Participar ahora',
    },
    'open-league': {
      header: 'THE OPEN LEAGUE',
      description: 'Completa la Fanton Quest y obtén NFT Soulbound memorables',
      action: 'Join',
    },
  },
  'single-match-filters': {
    'main-events': 'Eventos principales',
    'single-games': 'Partidos individuales',
  },
  'player-stat-values': {
    assist: 'Asistencias',
    'accurate-passes': 'Pases acertados',
    'accurate-crosses': 'Centros precisos',
    'big-chances-created': 'Oportunidades creadas',
    'big-chances-missed': 'Oportunidades falladas',
    'blocked-shots': 'Tiros bloqueados',
    cleansheet: 'Portería a cero',
    clearances: 'Portería a cero',
    'clearance-offline': 'Despeje lateral',
    'error-lead-to-goal': 'Error que resultó en gol',
    'fouls-drawn': 'Faltas recibidas',
    fouls: 'Faltas',
    goals: 'Goles',
    'goals-conceded': 'Goles recibidos',
    'hit-woodwork': 'Impactar en los postes o el travesaño',
    interceptions: 'Intercepciones',
    'key-passes': 'Pases clave',
    'long-balls-won': 'Balones largos ganados',
    'minutes-played': 'Minutos jugados',
    'miss-pass': 'Pase fallado',
    'own-goals': 'Goles en propia puerta',
    'penalties-awarded': 'Penaltis otorgados',
    'penalties-saved': 'Penaltis detenidos',
    'penalties-misses': 'Penaltis fallados',
    'penalties-committed': 'Penaltis cometidos',
    punches: 'Despejes con puños',
    'red-cards': 'Tarjetas rojas',
    saves: 'Atajadas',
    'saves-inside-box': 'Atajadas dentro del área',
    'show-on-target': 'Disparo a puerta',
    'successful-dribbles': 'Regates exitosos',
    trackles: 'Entradas',
    'average-points': 'Puntos promedio',
    'yellow-cards': 'Tarjetas amarillas',
  },
  'player-detailed-info.stats-for-last-n-matches': 'Estadísticas de los últimos {{n, number}} partidos',
  'common.actions.show-more': 'Mostrar más',
  'common.actions.hide': 'Ocultar',
  'common.actions.dont-show-again': 'No mostrar más',
  'no-games-in-league-placeholder.label': 'En esta liga no hay torneos',
  'no-games-in-league-placeholder.description': 'Elige una liga diferente',
  'common.actions.create-new-team': 'Crear un equipo nuevo',
  'common.actions.play-premium': 'Jugar un torneo premium',
  'common-team-n': 'Equipo {{n}}',
  'common.errors.system-error':
    'Algo salió mal. Inténtalo de nuevo más tarde o ponte en contacto con el servicio de asistencia.',
  'wallet.withdrawal-request-created.message': 'Tu solicitud de retiro está pendiente',
  'wallet-page.title': 'Saldo',
  'wallet-page.total': 'Balance total',
  'wallet-page.receive': 'Recibir',
  'wallet-page.send': 'Enviar',
  'wallet-page.swap': 'Intercambio',
  'wallet-page.stake': 'Stake',
  'wallet-page.not-enough-money-msg': 'Fondos insuficientes, recarga tu saldo',
  'wallet-page.no-transactions-placeholder': 'Tus transacciones aparecerán aquí',
  'wallet-page.tickets.title': 'Mis entradas',
  'wallet-page.transactions.title': 'Historial de transacciones',
  'wallet-page.tokens.change-to': 'Cambio por',
  'transactions-list.today': 'Hoy',
  'transactions-list.yesterday': 'Ayer',
  'select-deposit-method.title': 'Seleccionar método de depósito',
  'enter-amount-page.title': 'Introduce la cantidad en TON',
  'common.continue-btn.label': 'Continuar',
  'enter-amount-page.commission': 'comisión',
  'enter-amount.no-commission': 'Sin comisión',
  'select-wallet-page.title': 'Seleccionar monedero',
  'select-wallet-page.card-top-up-info':
    'Tu saldo se restablecerá con el precio del ticket. Si la cantidad depositada fuera mayor que el coste del torneo, en el futuro podrás retirar esta cantidad o pagar otro torneo. Todos los proveedores tienen diferentes cantidades mínimas de depósito.',
  'bitcoin-transaction-lasting':
    'La confirmación del pago en la red BTC puede tardar hasta 30 minutos. Una vez que la red BTC haya confirmado la transacción, se cargará tu saldo de juego.',
  'other-wallet-transfer-page.title': 'Confirmación de pago',
  'other-wallet-transfer-page.instruction':
    'Para recargar el saldo de juego, copia los campos y pégalos en la aplicación de tu monedero',
  'other-wallet-transfer-page.instruction.telegram-stars':
    'Próximamente podrán recargar su saldo de Fanton usando la moneda Telegram Stars. Estamos trabajando en esta opción para todos los jugadores.',
  'other-wallet-transfer-page.amount-in': 'Cantidad en',
  'other-wallet-transfer-page.wallet': 'Monedero',
  'other-wallet-transfer-page.comment': 'Comentario',
  'other-wallet-transfer-page.comment-instruction':
    'Copia este comentario y pégalo en el campo de comentarios de tu monedero para que podamos rastrear la transacción',
  'other-wallet-transfer-page.return-to-balance': 'Volver al saldo',
  'select-withdrawal-wallet-page.wallets.wallet.label': 'Dirección del monedero',
  'withdrawal-request-created.placeholder.label': 'Se ha creado la solicitud de retiro',
  'withdrawal-request-created.placeholder.description':
    'La cantidad será enviada a tu monedero en 24 horas. Nuestro gestor se pondrá en contacto contigo en breve',
  'select-withdrawal-wallet-page.no-wallets.text': 'Connect wallet you want to withdraw to',
  'common.action.ok': 'OK',
  'transaction-operation.money-reward.label': 'Prize',
  'transaction-operation.game-ticket.label': 'Premium tournament',
  'transaction-status.created-label': 'Created',
  'transaction-status.approve_required-label': 'Waiting for approve',
  'transaction-status.approved-label': 'Approved',
  'transaction-status.started-label': 'Started',
  'transaction-status.completed-label.sent': 'Enviado',
  'transaction-status.completed-label.received': 'Recibido',
  'select-withdrawal-wallet-page.withdrawal-error': 'Parece que no tienes suficiente dinero en tu monedero.',
  'wallets.other-wallet': 'Otro monedero',
  'neocrypto.payment-succeeded-page.placeholder.label': 'Success!',
  'neocrypto.payment-succeeded-page.placeholder.description-default':
    'We are delighted to inform you\nthat we received your payment',
  'waiting-for-transfer-page.placeholder.description-btc':
    'La confirmación del pago en la red BTC puede tardar hasta 30 minutos. Una vez que la red BTC haya confirmado la transacción, se restablecerá tu saldo de juego.',
  'waiting-for-transfer-page.placeholder.description-default': 'Estamos a la espera de la recarga del saldo',
  'waiting-for-transfer-page.placeholder.label': 'Procesando transacción',
  'prizes-claim': {
    'title.nft-tier': 'NFT Tier',
    description: {
      'special-card': 'Get special NFT player card',
      'card-avaliable-to': 'La tarjeta estará disponible el',
      'will-be-sent-to-tonkeeper': 'Se enviarán a tu Tonkeeper',
      'get-5-cards': 'Get 5 player cards',
      'pack-avaliable-to': 'The pack will be available to',
      'pack-common-tier': 'Pack Common Tier',
      'connect-ton-wallet': '¡Conecta TON wallet\npara obtener tu premio!',
      'will-be-credited-to-your-balance': 'Será acreditado a tu saldo',
      'ticket-to-x-tournament': 'Ticket para el torneo {{tournament}}',
      'will-be-sent-to-stonfi': `Se acreditarán en tu monedero. No hay necesidad de enviar nada a los monederos`,
    },
    action: {
      'get-prize': 'Obtener premio',
    },
  },
  'common.search.placeholder': 'Buscar un jugador',
  'common.game.statuses.paid': 'Pagado',
  'common.game.statuses.in-progress': 'En curso',
  'common.game.statuses.completed': 'Completado',
  'common.game.statuses.get-prize': 'Obtener premio',
  'common.game.statuses.join': 'Participar',
  'common.common': 'Común',
  'common.nft': 'NFT',
  'common.tier': 'Tier',
  'common.free': 'Gratuito',
  'common.tickets': 'Tickets',
  'prizes.participiants-in-tournament': 'Participantes en el torneo',
  'prizes.guaranteed-prize': 'Premio garantizado',
  'prizes.info-about-participiants': 'Prizes are calculated from the number of participants in the tournament',
  'common.ratio': 'Coeficiente',
  'common.price': 'Precio',
  'common.ton-connect.sign-in-btn': 'Conectar monedero',
  'common.place-plural_one': '{{count}}º lugar',
  'common.place-plural_two': '{{count}}º lugar',
  'common.place-plural_few': '{{count}}º lugar',
  'common.place-plural_other': '{{count}}º lugar',
  'home.tabs.main.label': 'Central',
  'home.tabs.nft.label': 'NFT',
  'home.tabs.prizes.label': 'Premios',
  'home.tabs.support.label': 'Soporte',
  'home.section.tournaments.header': 'TORNEOS',
  'home.section.tournaments.all-tournaments': 'Todos los torneos',
  'home.section.tournaments.see-all-btn': 'Más',
  'home.section.tournaments.no-games.label': 'No hay próximos torneos',
  'home.section.my-tournaments.header': 'MIS TORNEOS',
  'home.section.my-tournaments.see-all-btn': 'Más',
  'home.section.my-tournaments.placeholder.no-games.label': 'Aún no has empezado ningún partido',
  'home.section.my-tournaments.placeholder.label': 'Forma un equipo para participar en los torneos',
  'home.section.my-tournaments.placeholder.action': 'Participar en el próximo torneo',
  'home.slider.prizes.see-all-btn': 'Más',
  'home.slider.prizes.close-btn': 'Cerrar',
  'prizelist.prizes.n-place.title': '{{n, number}} lugar',
  'prizelist.prizes.n-m-places.title': '{{n, number}} - {{m}} lugar',
  'prizelist.prizes.top-n-%.title': 'Top {{n, number}}%',
  'prizelist.prizes.n-m-%.title': '{{n, number}}-{{m}}%',
  'prizelist.play-tournaments': 'Participar en torneos',
  'home.slider.nft-cards.header': 'TARJETAS NFT',
  'home.slider.nft-cards.see-all-btn': 'Más',
  'tournaments.header.title': 'Torneos',
  'common.tabs.paid.title': 'Premium',
  'games.tabs.current': 'En curso',
  'games.tabs.past': 'Terminado',
  'cards.nft-cards.header': 'Tarjetas NFT',
  'cards.nft-cards.buy-cards-btn': 'Obtener tarjetas en getgems',
  'cards.nft-cards.placeholder.label': 'No tienes tarjetas NFT',
  'cards.nft-cards.placeholder.description': 'Primero compra una',
  'cards.nft-cards.placeholder.action': 'Obtener tarjetas en getgems',
  'cards.tabs.common': 'Common',
  'cards.tabs.nft': 'NFT',
  'cards.placeholder.description.hint': 'Inténtalo de nuevo',
  'cards.placeholder.label': 'Tarjeta no encontrada',
  'cards.card-advanced.player-team': 'Equipo del jugador',
  'cards.card-advanced.opposite-team': 'Equipo contrario',
  'cards.card-advanced.avg-pts-in-last-n': 'Promedio de puntos en los últimos {{n, number}}',
  'notifications.header.title': 'Notificaciones',
  'notifications.placeholder.label': 'No hay nuevas notificaciones',
  'notifications.placeholder.description': 'Has leído todos\nlos mensajes',
  'notifications.notification.chip.get-prize': 'Get prize', // Translation missing,
  'notifications.notification.chip.collect': 'Collect', // Translation missing,
  'notifications.notification.default-name': 'Free common pack was awarded', // Translation missing,
  'filters.filter.tier.label': 'Tier',
  'filters.filter.position.label': 'Posición del jugador',
  'filters.header.title': 'Filtro',
  'filters.reset-btn.label': 'Restablecer todo',
  'filters.filter.club.label': 'Club',
  'filters.filter.country.label': 'País',
  'filters.filter.championship.label': 'Campeonato',
  'filters.aplly-btn.label': 'Aplicar',
  'filter.apply-btn.label': 'Aplicar',
  'filter.header.title': 'Filtro',
  'games.placeholder.label': 'Torneos no encontrados',
  'games.placeholder.description-current': 'Elige entre los torneos en curso o vuelve más\n tarde',
  'games.placeholder.description-past': 'Los torneos en los que hayas\nparticipado aparecerán aquí',
  'games.placeholder.action': 'Participar en un nuevo torneo',
  'game.tab.prizes': 'Premios',
  'game.tab.team': 'Equipo',
  'game.tab.matches': 'Partidos',
  'game.tab.results': 'Resultados',
  'game.participants': 'Participantes',
  'game.points-limit': 'Máxima total',
  'game.my-place': 'Lugar',
  'game.teams.duplicate-onboarding': {
    header: 'Duplicar equipo',
    description: 'Crea un duplicado del equipo',
    action: 'ОК',
  },
  'position-details.header': 'Info',
  'position-details.points-detail.goal': 'Gol (sin tiro penal)',
  'position-details.points-detail.own-goal': 'Autogol',
  'position-details.points-detail.assist': 'Asistencia',
  'position-details.points-detail.match-without-missing-gt-60': 'Partido sin recibir goles (>60 min)',
  'position-details.points-detail.match-without-missing-lt-60': 'Partido sin recibir goles (<60 min)',
  'position-details.points-detail.earned-penalty': 'Generó un tiro penal',
  'position-details.points-detail.penalty-scored': 'Gol de tiro penal',
  'position-details.points-detail.penalty-missed': 'Tiro penal fallado',
  'position-details.points-detail.penalty-saved': 'Tiro penal detenido',
  'position-details.points-detail.fouled-penalty': 'Falta de tiro penal',
  'position-details.points-detail.match-participation': 'Participación en el partido',
  'position-details.points-detail.player-on-field-gt-60': 'Jugador en el campo (>60 min)',
  'position-details.points-detail.first-yellow-card': 'Primera tarjeta amarilla',
  'position-details.points-detail.second-yellow-card': 'Segunda amarilla',
  'position-details.points-detail.direct-red-card': 'Tarjeta roja directa',
  'position-details.points-detail.team-win': 'El equipo ganó',
  'position-details.points-detail.team-draw': 'Empate',
  'position-details.points-detail.team-loss': 'El equipo perdió',
  // V3 Scoring system
  'position-details.points-detail.GOALS': 'Goles',
  'position-details.points-detail.ASSISTS': 'Asistencias',
  'position-details.points-detail.ACCURATE_PASSES': 'Pases precisos',
  'position-details.points-detail.ACCURATE_CROSSES': 'Centros precisos',
  'position-details.points-detail.KEY_PASSES': 'Pases clave',
  'position-details.points-detail.MISS_PASS': 'Pases fallidos',
  'position-details.points-detail.FOULS_DRAWN': 'Faltas recibidas',
  'position-details.points-detail.FOULS': 'Faltas cometidas',
  'position-details.points-detail.SAVES': 'Paradas de portero',
  'position-details.points-detail.SAVES_INSIDE_BOX': 'Paradas dentro del área',
  'position-details.points-detail.PUNCHES': 'Rechazos del portero',
  'position-details.points-detail.CLEANSHEET': 'Portería a cero',
  'position-details.points-detail.MINUTES_PLAYED': 'Minutos jugados',
  'position-details.points-detail.INTERCEPTIONS': 'Intercepciones',
  'position-details.points-detail.CLEARANCES': 'Despejes',
  'position-details.points-detail.TACKLES': 'Entradas/tacleadas',
  'position-details.points-detail.SHOTS_ON_TARGET': 'Tiros a puerta',
  'position-details.points-detail.BLOCKED_SHOTS': 'Tiros bloqueados',
  'position-details.points-detail.HIT_WOODWORK': 'Remates al poste',
  'position-details.points-detail.CLEARANCE_OFFLINE': 'Despeje fuera de línea',
  'position-details.points-detail.SUCCESSFUL_DRIBBLES': 'Regates exitosos',
  'position-details.points-detail.PENALTIES_MISSES': 'Penaltis fallidos',
  'position-details.points-detail.PENALTIES_SAVED': 'Penaltis detenidos',
  'position-details.points-detail.PENALTIES_WON': 'Penaltis ganados',
  'position-details.points-detail.PENALTIES_COMMITTED': 'Penaltis cometidos',
  'position-details.points-detail.LONG_BALLS_WON': 'Balones largos ganados',
  'position-details.points-detail.REDCARDS': 'Tarjetas rojas',
  'position-details.points-detail.YELLOWCARDS': 'Tarjetas amarillas',
  'position-details.points-detail.OWN_GOALS': 'Goles en propia puerta',
  'position-details.points-detail.ERROR_LEAD_TO_GOAL': 'Errores que conducen a un gol en contra',
  'position-details.points-detail.GOALS_CONCEDED': 'Goles encajados',
  'position-details.points-detail.BIG_CHANCES_MISSED': 'Grandes ocasiones falladas',
  'position-details.points-detail.BIG_CHANCES_CREATED': 'Grandes ocasiones creadas',

  'position-details.reserve-player.description':
    'Recibe el 70% de los puntos por su posición si un jugador de la plantilla principal no entró en el campo. Se considera el momento anterior a la salida del jugador del campo',
  'team.position.goalkeeper': 'PT',
  'team.action-label.select': 'SELECCIONAR',
  'team.position.back': 'DEF',
  'team.position.midfield': 'MC',
  'team.position.forward': 'DEL',
  'team.position.reserve': 'SUPL',
  'team.position.any': 'CUALQUIERA',
  'team.position.any-player': 'JUGADOR',
  'team.position.any-player.subtitle': 'Jugador',
  'team.position.all-players': 'CUALQUIER JUGADOR',
  'team.payment-status.in-progress': 'Estamos esperando la confirmación del\npago. Puede tardar hasta 10 minutos',
  'team.payment-status.success': 'Pago efectuado',
  'team.nft-card-missing': 'Elige al menos una tarjeta NFT para\nparticipar en el torneo NFT',
  'team.select-any-player-header': 'Seleccionar jugador',
  'team.select-at-least-n-rarity-cards': 'Seleziona almeno {{n, number}} carta {{rarity}}',
  'team.select-up-to-n-rarity-cards': 'Seleziona fino {{n, number}} carta {{rarity}}',
  'team.rarity-cards-are-not-allowed': '{{rarity}} non sono ammessi',
  'common.or': 'o',
  'common.score': 'Resultado',
  'common.dates': 'Fechas',
  'common.game': 'Torneo',
  team: {
    onboarding: {
      text: {
        begginerV2: '— Uso ilimitado de NFT raros\n— Límite de 2 NFT épico\n— Límite de 1 NFT legendarios',
        beginner: 'Para participar, necesitas al menos un NFT de cualquier rareza',
        amateur: '— Uso ilimitado de NFT raros\n— Límite de 1 NFT épico\n— No se permiten NFT legendarios',
        pro: '— Uso ilimitado de NFT Raros, Common y Épicos\n— Límite de 1 tarjeta NFT Legendaria',
        legendary: 'Para participar, selecciona al menos 2 NFT legendarios',
      },
    },
  },
  'cards.market-status.filters.all-cards': 'Todas las tarjetas',
  'cards.market-status.filters.on-sale': 'A la venta',
  'cards.market-status.filters.at-auction': 'Subasta',
  'cards.market-status.filters.not-for-sale': 'No a la venta',
  'commmon.sort-options.by-price': 'Ordenar por precio',
  'common.my-nft': 'My NFT',
  'common.market': 'Market',
  'cards.actions.see-on-ggems': 'See on Getgems.io',
  'cards.actions.buy-on-ggems': 'Buy on Getgems.io',
  'cards.actions.buy-on-ggems-with-price': 'Buy {{ price }}',
  'filters.filter.rarity.label': 'Rareza',
  'filters.filter.price.min-price.label': 'Costo (min)',
  'filters.filter.price.max-price.label': 'Costo (max)',
  'filters.filter.price.enter-price.placeholder': 'Enter price',
  'team.captain-missing': 'Selecciona un capitán para iniciar el torneo',
  'position-details.captain': 'Capitán',
  'team.too-much-players-from-same-team':
    'No se puede inscribir un equipo con más de {{n, number}} jugadores del mismo club de fútbol. Por favor, cambia la plantilla',
  'team.too-much-points': 'La suma de los puntos de los jugadores excede el puntaje total. Cambia a los jugadores',
  'team.pop-up.auth-required': 'Regístrate para participar en el torneo',
  'game.actions.pay': 'Pagar',
  'game.actions.pay-in-currency': 'Pagar {{n, number}} {{c}}',
  'game.actions.play-with-this-team': 'Iniciar torneo',
  'game.actions.save-changes': 'Guardar cambios',
  'game.actions.placeholder.completed': 'El torneo ya ha terminado',
  'game.actions.placeholder.in-progress.line-1': 'Ya no es posible cambiar de equipo. El torneo',
  'game.actions.placeholder.in-progress.line-2': 'finalizará en:',
  'game.results.placeholder.no-started.label': 'No tan rápido!',
  'game.results.placeholder.no-started.description':
    'Los resultados estarán disponibles una vez\nfinalizada la semana de juego',
  'game.results.placeholder.no-teams.label': 'No hay equipos inscritos',
  'game.results.placeholder.no-teams.description': 'Este tour no tiene participantes',
  'game.results.your-position': 'Tú',
  'game.results.your-place-is': 'Tu posición',
  'game.results.out-m': 'de {{m}}',
  'common.scoring-system': 'Sistema de puntuación',
  'game.results.points-label': 'pts',
  'game.prizes.placeholder.no-prize.label-top-n-pct': 'Increíble,\nhas entrado en el top {{n, number}}%',
  'game.prizes.placeholder.no-prize.description-n-teams-beaten': 'Y vencido a {{n}} equipos',
  'game.prizes.placeholder.no-prize.same-n-usd-prize-in-premium':
    'Con el mismo equipo, podrías haber ganado {{n}} $ en nuestro Torneo Premium',
  'game.prizes.placeholder.no-prizes.action': 'Volver a jugar',
  'game.prizes.placeholder.prizes-claimed.label': '¡El premio ha sido entregado!',
  'game.prizes.placeholder.prizes-ready.label.line-1': '¡Felicidades!',
  'game.prizes.placeholder.prizes-ready.label.line-2': `Has terminado`,
  'game.prizes.placeholder.prizes-ready.action': 'Obtener premio',
  'game.prizes.placeholder.prizes-claimed.action': 'Volver a jugar',
  'game.prizes.placeholder.prizes-claimed.description.line-1': 'Ya has recolectado las tarjetas de esta ronda',
  'game.prizes.placeholder.prizes-claimed.description.line-2': '',
  'game.prizes.placeholder.prizes-ready.description': '¡Obtén el premio!',
  'team-created.placeholder.label': 'Estás inscrito en la',
  'team-created.placeholder.action': 'OK',
  'team-created.placeholder.duplicate-to-premium': 'Dublicar equipo en la versión premium',
  'team-created.placeholder.duplicate-team': 'Duplicar equipo',
  'team-created.placeholder.back-to-menu': 'Volver al menú principal',
  'pack-claimed.placeholder.label': '¡El premio ha sido entregado!',
  'pack-claimed.placeholder.description.line-1': 'Ya has recolectado las tarjetas de esta ronda',
  'pack-claimed.placeholder.description.line-2': '',
  'pack-claimed.placeholder.action': 'Watch my cards',
  'team-details.total-points.titile': 'Goles marcados',
  'team-details.total-points.points-label': 'pts',
  'team-details.player.action.title': 'Acción del jugador',
  'team-details.player.action.not-applicable': 'No aplicable',
  'community-thumb.label': 'COMUNIDAD',
  'community-thumb.btn.label': 'Únete',
  'prizes.header.title': 'Premios',
  'support.label': 'Support',
  'support.placeholder.label': 'Contact support',
  'support.placeholder.description': 'To contact the support service, you will leave the game',
  'support.placeholder.action': 'Contact support',
  'referral-program': {
    header: 'Invitar a un amigo',
    invited: 'Invitados',
    played: 'Jugaron',
    tickets: 'Tickets',
    friends: 'Amigos',
    'awarded-tickets': 'Tickets premiados',
    actions: {
      share: 'Compartir enlace',
      'copy-to-friends': 'Copiar enlace y enviar a amigos',
    },
  },
  'common.copied': 'Copiado',
  'telegram-wallet-info': '«Telegram Wallet» – monedero oficial de Telegram',
  'balance-repenisment-ton': {
    header: 'Recarga de saldo',
    actions: {
      deposit: 'Recargar',
      'deposit-in-telegram-wallet': 'Recargar en Telegram Wallet',
      'pay-another-cryptocurrencies': 'Pagar con otra criptomoneda',
    },
  },
  'friends-games': {
    header: 'Jugar con amigos',
    'play-with-friends': 'Jugar con amigos',
    'my-tournaments': 'Mis torneos',
    'by-invitation': 'Por invitación',
    'create-new': 'Crear un nuevo torneo',
    'mine-placeholder': {
      description: 'Los torneos que crees aparecerán aquí',
    },
    'invited-placeholder': {
      header: 'Torneos por invitación',
      description: 'Los torneos a los que hayas sido invitado aparecerán aquí',
    },
    'past-placeholder': {
      header: 'Torneos pasados',
      description: 'Los torneos que hayas jugado con tus amigos aparecerán aquí',
    },
    banner: {
      label: 'JUGAR CON AMIGOS',
      description: 'Crea torneos y juega a Fantasy Football con tus amigos',
      action: 'Jugar',
    },
  },
  'create-game': {
    header: 'Elegir torneo',
    info: 'Sólo puedes crear un torneo en cada liga',
  },
  'common.create': 'Crear',
  'game-created': {
    header: 'Felicidades, has creado un torneo',
    description: 'Crea tu equipo e invita a tus amigos a competir contigo.',
    action: 'Crear equipo',
  },
  'invite-friends': 'Invitar a amigos',
  pvp: {
    homeScreenLinkLabel: 'Encuentro PvP',
    label: 'Encuentro PvP',
    myPvp: 'Mi PvP',
    pvp: 'PvP',
  },
  'common.firstPlace': '1er lugar',
  leaderboars: {
    label: 'Clasificación',
    levels: {
      amateur: 'Amateur',
      pro: 'Pro',
      worldClub: 'Club mundial',
    },
    nextLevel: 'Siguiente nivel',
    level: 'Nivel',
    trophies: 'Trophies',
    trophies_count_other: 'Trophies',
    league: 'Liga',
    seasons: 'Temporadas',
    howToEarnTrophies: '¿Cómo ganar trofeos?',
    currentPts: 'Trofeos actuales',
    myPts: 'Mis trofeos',
    myPrize: 'Mis premios',
    leaders: 'Líderes',
    noParticipantsPlaceholder: {
      labelPt1: 'Necesitas',
      labelPt2: 'para entrar a esta liga',
      description: 'Juega más torneos para avanzar a esta liga',
    },
    seasonOverPlaceholder: {
      label: 'La {{name}} ha terminado',
      description: 'Es hora de retirar los premios',
    },
  },
  'team.captain-not-nft-in-nft-game': 'Solo se puede seleccionar una tarjeta NFT como capitán',
  tasks: {
    'to-play-in-tournament-with-garantee': 'Para jugar en el Torneo con un pool de premio {{s}}',
    'to-play-in-tournament': 'Para jugar en el Torneo',
    start: 'Empezar',
    'check-status': 'Estado de comprobación',
    continue: 'Continuar con las tareas',
    'start-over': 'Iniciar con las tareas',
    'complete-tasks': 'Completa las tareas',
  },
  'ido-promo.action': 'Unirse',
  'ido-promo.label': 'BIENVENIDO A $FTON',
  'ido-promo.description': 'Participa en la campaña para ganar $85,000 y únete a la whitelist de $FTON en IDO.',
  'common.lootbox': 'Lootbox',
  'no-lootboxes-placeholder': {
    label: 'Lootboxes are not available',
    description: 'We will add them soon',
  },
  'lootboxes-cards-available': 'Tarjeta disponible',
  'lootboxes-box-avaliable-till': 'Caja disponible hasta el',
  'lootboxes-buy': 'Buy {{amount}} {{currency}}',
  'lootboxes-chance': 'Posibilidad',
  'get-card': 'Get card',
  'prize-pool': 'Pool de premios',
  'euro2024promo.label': '@WHALE EURO2024 POOL DE PREMIO $100 000',
  'tickets-to-the': 'Tickets para la',
  'next-round': 'siguiente ronda',
  leaderboards: 'Clasificación',
  'how-to-play-winline': 'Cómo jugar en la Winline torneo',
  'winline-tournaments': 'Torneos',
  'playoff-prize': 'pool de premios',
  'register-team-with-task': 'Pagar con tarea',
  'euro-details': 'Detalles',
  'euro-n-percents-get-tikcet': 'El {{n}}% de los mejores equipos consigue tickets para la siguiente ronda',
  'euro-1-n-get-ticker': '1-{{n}} plazas consiguen tickets para la siguiente ronda',
  'ticket-to-next-round': 'Ticket para la siguiente ronda',
  'whale-cashback-promo': {
    label: 'Juega en la EURO-2024 en @WHALE',
    description: 'Apuesta en la EURO-2024 y obtén hasta un 30% de cashback en @Whale',
  },
  'euro-teams': 'Equipos',
  'euro-no-prize': 'No premio',
};

export default ESTranslation;
