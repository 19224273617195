import { StorageKeys, StorageUtility } from '@Utils/storageUtility';
import { IS_QA } from '@Global/constants';
import { TAppEnv } from '@Global/store/appContext';

export const PLATFORMS = {
  IOS_ANY: 'ios_any',
  ANDROID_ANY: 'android_any',
  OTHER: 'other',
};

export const isAndroidMobile = () => {
  return navigator.userAgent.match(/Android/i) && navigator.userAgent.match(/Mobile/i);
};

const isAndroid = () => {
  return navigator.userAgent.match(/Android/i);
};

export const isIOS = () => {
  return (
    navigator.userAgent.match(/iPhone|iPad|iPod/i) ||
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

/**
 * @func getPlatform
 * @returns {string} - Возвращает строку с названием платформы полученным из window.navigator.userAgent
 */
export const getPlatform = () => {
  if (isAndroid()) {
    return PLATFORMS.ANDROID_ANY;
  }

  if (isIOS()) {
    return PLATFORMS.IOS_ANY;
  }

  return PLATFORMS.OTHER;
};

export function isMobile() {
  return window.innerWidth <= 500;
}

export const getAppPlatform = (): TAppEnv => {
  if (window.location.pathname.startsWith('/neocrypto/')) {
    return 'neocrypto';
  } else if (window.location.href.includes('utm_source=tonkeeper')) {
    return 'tonkeeper';
  } else if (window.location.href.includes('tgWebAppData') || sessionStorage.getItem('is_tg') === 'true') {
    sessionStorage.setItem('is_tg', 'true');
    return 'telegram';
  }
  return 'web';
};

export const isProdByLink = (link: string) => {
  return !link.includes('/qa');
};

export const getIsTelegramStarsEnabled = (appEnv: TAppEnv) => {
  return appEnv === 'telegram' || StorageUtility.getItem<boolean>(StorageKeys.IS_TG);
};

export const getPathname = (pathname = window.location.pathname) => {
  return IS_QA ? pathname.replace('/qa', '') : pathname;
};
