import { createSlice } from '@reduxjs/toolkit';
import { GameLifecycleStage } from '@Global/apollo/types';
import { GamesFilterInitialState, gamesFilterSliceProto } from './consts';

export const allGamesFilters = createSlice({
  name: 'allGamesFilters',
  ...{
    ...gamesFilterSliceProto,
    initialState: {
      ...gamesFilterSliceProto.initialState,
      lifecycle: [GameLifecycleStage.Upcoming, GameLifecycleStage.Ongoing],
      isNft: null,
      isSingleMatch: null,
    } as GamesFilterInitialState,
  },
});

export const allGamesFiltersReducer = allGamesFilters.reducer;
