import { Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { initAmplitude } from '@Utils/amplitude';
import { setupTelegramWindowObject } from '@Utils/telegram';
import { Buffer } from 'buffer';
import { GenericApolloProvider } from '@/apollo';
import { ContextInterLayer } from '@Global/store';
import { App } from './App';
import initI18n from './i18n/config';
import './index.css';
import { store } from './store/redux';
import './tailwind-input.css';
import { ErrorBoundary } from './ui/ErrorBoundary';
import { addMatchMediaSupport } from './utils/addMatchMediaSupport';
import { initAdsGram } from './utils/adsgram/initAdsGram';
import { initSentry } from './utils/sentry';
import './variables.css';

window.Buffer = window.Buffer || Buffer;
window.Telegram.WebApp.expand();

if ('scrollRestoration' in history) {
  history.scrollRestoration = 'auto';
}

initSentry();

// Функция определения iOS устройства
const isIOS = () => {
  return (
    navigator.userAgent.match(/iPhone|iPad|iPod/i) ||
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)
  );
};

// Функция применения стилей скролла
// Это костыль тк в браузере телеграмм на iphone,
//когда мы делаем html элемент со скролом появляется белая полоса внизу экрана
const applyScrollStyles = () => {
  if (isIOS()) {
    // Стили для iOS
    document.documentElement.style.overflowY = 'hidden';
    document.documentElement.style.overflowX = 'hidden';
    document.body.style.overflowY = 'scroll';
  } else {
    // Стили для остальных устройств
    document.documentElement.style.overflowY = 'scroll';
    document.documentElement.style.overflowX = 'hidden';
  }
};

setupTelegramWindowObject();
initI18n();
initAmplitude();
initAdsGram();
addMatchMediaSupport();

// Применяем стили при загрузке
applyScrollStyles();

/** инициалзиатор */
const Fanton = () => {
  useEffect(() => {
    // Можно добавить повторное применение стилей при необходимости
    applyScrollStyles();
  }, []);

  return (
    <ErrorBoundary>
      <GenericApolloProvider>
        <ContextInterLayer>
          <Suspense fallback={null}>
            <Provider store={store}>
              <App />
            </Provider>
          </Suspense>
        </ContextInterLayer>
      </GenericApolloProvider>
    </ErrorBoundary>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<Fanton />);
